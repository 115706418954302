<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import IntroductionStep from '~/src/Domain/DataRequest/Step/IntroductionStep'
import SummaryStep from '~/src/Domain/DataRequest/Step/SummaryStep'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import useDataRequestNavigation from '~/src/UserInterface/DataRequest/composables/useDataRequestNavigation'
import useDataRequestorState from '~/src/UserInterface/DataRequest/composables/useDataRequestorState'

const { getDataRequest } = useDataRequestorState()
const dataRequest = getDataRequest()
const {
  route,
  getStepLocalePath,
  getIntroductionStepLocalePath,
  getSummaryStepLocalePath,
} = useDataRequestNavigation()

const dataRequestId = route.params.dataRequestId as string
const dataRequestSlug = new Slug(route.params.dataRequestSlug as string)
const dataRequestRecipientId = route.params.dataRequestRecipientId as string

const currentSlug = ref<Slug>(new SummaryStep().slug)
const isOnIntroductionStep = computed(() => currentSlug.value.equals(new IntroductionStep().slug))

watch(
  () => route.fullPath,
  (newValue) => {
    if (
      newValue
      === getIntroductionStepLocalePath(dataRequestId, dataRequestSlug, dataRequestRecipientId)
    ) {
      currentSlug.value = new IntroductionStep().slug
    } else if (
      newValue
      === getSummaryStepLocalePath(dataRequestId, dataRequestSlug, dataRequestRecipientId)
    ) {
      currentSlug.value = new SummaryStep().slug
    }
  },
  { immediate: true },
)
watch(
  () => route.params.stepSlug,
  (newValue) => {
    if (newValue === undefined || typeof newValue !== 'string') {
      return
    }

    currentSlug.value = new Slug(newValue)
  },
  { immediate: true },
)

const nextStepPage = ref<string | undefined>(undefined)
const previousStepPage = ref<string | undefined>(undefined)

watch(
  currentSlug,
  (newValue) => {
    if (!dataRequest.value.stepCollection.hasNextStep(newValue)) {
      nextStepPage.value = undefined
    } else {
      const nextSlug = dataRequest.value.stepCollection.getNextStep(newValue).slug
      if (nextSlug.equals(new SummaryStep().slug)) {
        nextStepPage.value = getSummaryStepLocalePath(dataRequestId, dataRequestSlug, dataRequestRecipientId)
      } else {
        nextStepPage.value = getStepLocalePath(dataRequestId, dataRequestSlug, dataRequestRecipientId, nextSlug)
      }
    }

    if (!dataRequest.value.stepCollection.hasPreviousStep(newValue)) {
      previousStepPage.value = undefined
    } else {
      const previousSlug = dataRequest.value.stepCollection.getPreviousStep(newValue).slug
      if (previousSlug.equals(new IntroductionStep().slug)) {
        previousStepPage.value = getIntroductionStepLocalePath(dataRequestId, dataRequestSlug, dataRequestRecipientId)
      } else {
        previousStepPage.value = getStepLocalePath(dataRequestId, dataRequestSlug, dataRequestRecipientId, previousSlug)
      }
    }
  },
  { immediate: true },
)
</script>

<template>
  <div class="main-content d-flex flex-column">
    <data-request-navbar-top :image="dataRequest.organisation.logo" />

    <div class="flex-grow-1">
      <ui-container
        fluid
        class="mb-4"
      >
        <div class="row">
          <div class="col-12">
            <ui-page-header
              :title="$t('components.data_request.data_requestor.title')"
              :subtitle="
                $t('components.data_request.data_requestor.subtitle', {
                  organisationName: dataRequest.organisation.name,
                })
              "
            />
          </div>
          <div class="col-12">
            <ui-container fluid>
              <div class="row">
                <div
                  class="col-2 col-xl-2 col-lg-3 col-md-4 col-sm-5"
                >
                  <ui-card
                    class="h-100"
                    no-body
                  >
                    <data-request-navigator
                      :data-request="dataRequest"
                      :current-slug="currentSlug"
                    />
                  </ui-card>
                </div>
                <div class="col-10 col-xl-10 col-lg-9 col-md-8 col-sm-7 d-flex flex-column">
                  <div id="data-requestor">
                    <slot />
                  </div>

                  <ui-container
                    fluid
                    class="mt-4"
                  >
                    <ui-row>
                      <ui-col>
                        <div v-if="previousStepPage === undefined && nextStepPage !== undefined">
                          <p class="text-center">
                            <ui-button
                              variant="primary"
                              size="lg"
                              :to="nextStepPage"
                            >
                              {{ $t('components.data_request.data_requestor.start') }}
                            </ui-button>
                          </p>
                        </div>
                        <div
                          v-else
                        >
                          <div class="d-flex justify-content-between">
                            <ui-button
                              v-if="previousStepPage !== undefined"
                              variant="white"
                              :to="previousStepPage"
                            >
                              {{ $t('components.data_request.data_requestor.previous') }}
                            </ui-button>
                            <ui-button
                              v-if="nextStepPage !== undefined"
                              variant="primary"
                              :to="nextStepPage"
                            >
                              {{ $t('components.data_request.data_requestor.next') }}
                            </ui-button>
                          </div>
                        </div>
                      </ui-col>
                    </ui-row>
                  </ui-container>
                </div>
              </div>
            </ui-container>
          </div>
          <div
            v-if="isOnIntroductionStep"
            class="col-12 mt-4 mb-4 text-muted"
          >
            <ui-container fluid>
              <ui-row>
                <ui-col>
                  <identity-what-is-analytikal />
                </ui-col>
              </ui-row>
            </ui-container>
          </div>
        </div>
      </ui-container>
    </div>
    <div
      id="footer"
      class="bg-white w-100"
    >
      <layout-footer />
    </div>
    <portal-target id="app-bottom" />
  </div>
</template>
