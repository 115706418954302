<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import IntroductionStep from '~/src/Domain/DataRequest/Step/IntroductionStep'
import { Status } from '~/src/Domain/DataRequest/Step/Status/Status'
import type StepInterface from '~/src/Domain/DataRequest/Step/StepInterface'
import SummaryStep from '~/src/Domain/DataRequest/Step/SummaryStep'
import Slug from '~/src/Domain/Shared/Identifier/Slug'
import useDataRequestNavigation from '~/src/UserInterface/DataRequest/composables/useDataRequestNavigation'
import useStatusBuilderFactory from '~/src/UserInterface/DataRequest/composables/useStatusBuilderFactory'
import type DataRequestProjection from '~/src/UserInterface/DataRequest/projection/DataRequestProjection'

const props = defineProps<{
  dataRequest: DataRequestProjection
  step: StepInterface
  active?: boolean
}>()

const { route, getStepLocalePath, getIntroductionStepLocalePath, getSummaryStepLocalePath }
  = useDataRequestNavigation()
const stepStatusFactory = useStatusBuilderFactory()
const currentStatus = computed(() => stepStatusFactory.create(props.step, props.dataRequest))

const stepLocalePath = ref('')
watch(
  () => props.step.slug,
  (newValue) => {
    stepLocalePath.value = new SummaryStep().slug.equals(newValue)
      ? getSummaryStepLocalePath(
        route.params.dataRequestId as string,
        new Slug(route.params.dataRequestSlug as string),
        route.params.dataRequestRecipientId as string,
      )
      : new IntroductionStep().slug.equals(newValue)
        ? getIntroductionStepLocalePath(
          route.params.dataRequestId as string,
          new Slug(route.params.dataRequestSlug as string),
          route.params.dataRequestRecipientId as string,
        )
        : getStepLocalePath(
          route.params.dataRequestId as string,
          new Slug(route.params.dataRequestSlug as string),
          route.params.dataRequestRecipientId as string,
          props.step.slug,
        )
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <ui-list-group-item
    :to="stepLocalePath"
    class="border-0 d-flex align-items-center"
    :class="{
      'fw-bold': active,
    }"
    style="min-height: 42px"
  >
    <ui-icon
      v-if="currentStatus === Status.COMPLETED"
      icon="check"
      class="text-success fs-2 me-1 ms-3"
    />
    <ui-icon
      v-else-if="currentStatus === Status.PENDING"
      icon="arrow-clockwise"
      class="text-info fs-2 me-1 ms-3 rotate"
    />
    <ui-icon
      v-else-if="currentStatus === Status.HAS_ERRORS"
      icon="x"
      class="text-danger fs-2 me-1 ms-3"
    />
    <span
      :class="{
        'ms-3': currentStatus === Status.INITIAL,
        'ps-4': currentStatus === Status.INITIAL,
      }"
    >
      {{ step.title }}
    </span>

    <ui-badge
      v-if="step.isOptional"
      class="ms-2"
      pill
    >
      {{ $t('components.data_requests.navigator_item.optional') }}
    </ui-badge>
  </ui-list-group-item>
</template>
